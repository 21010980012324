<app-navbar></app-navbar>
<main>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>


<div class="version">
  <p>SDC Portal - {{stage}}-{{version}}</p>
  <p>Build Date and Time: {{ buildDateTime }}</p>
</div>
